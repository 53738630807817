(function () {
    'use strict';

    angular.module("adminApp")
        .factory('changeOrganizationModal', changeOrganizationModal);

    changeOrganizationModal.$inject = ["$uibModal"];
    changeOrganizationModalController.$inject = ["$scope", "$uibModalInstance"];

    function changeOrganizationModal($uibModal) {

        function open() {
            var modal = $uibModal.open({
                size: "md",
                templateUrl: "/static/templates/org/modal/changeOrganizationModal.html",
                controller: changeOrganizationModalController
            });
            return modal.result;
        }

        return {
            open: open
        };
    }

    function changeOrganizationModalController($scope, $uibModalInstance) {

        // TESTING
        $scope.organizations = [
            {
                name: "Org A",
                id: 1
            },
            {
                name: "Org B",
                id: 2
            },
            {
                name: "Org C",
                id: 3
            }
        ];
        // TESTING

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };
        $scope.test = {
            newOrganization: 2
        };
        $scope.selectNewOrganization = function () {
            $uibModalInstance.close($scope.s.newOrganization);
        };
    }
})();
